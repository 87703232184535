.full-bg-cover {
  @apply w-full h-full absolute top-0 left-0 object-cover object-center;
}

.video-embed {
  @apply w-full h-full absolute top-0 left-0;
}

.aspect-16x9 {
  position: relative;
}

.aspect-16x9::before {
  content: '';
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 56.25%;
}

.aspect-16x9::after {
  content: '';
  display: table;
  clear: both;
}

@media (max-width: 767px) {
  .clip-path-bottom {
    clip-path: polygon(0 0, 0 81.333333%, 100% 100%, 100% 0);
  }
}

@media (min-width: 768px) {
  .clip-path-left {
    clip-path: polygon(0 0, 0 100%, calc(100% - 120px) 100%, 100% 0);
  }
}

@media (min-width: 768px) {
  .clip-path-right {
    clip-path: polygon(120px 0, 0 100%, 100% 100%, 100% 0);
  }
}
