.enso-carousel {
  position: relative;
  overflow: hidden;
  width: 100vw;
  max-width: 100%;
}

.enso-carousel__slides {
  cursor: grab;
  display: flex;
  position: relative;
  top: 0;
  min-width: 100vw;
  left: -100%;
  height: 100%;
}

.enso-carousel__slides--sliding {
  transition: left 0.2s ease-out;
}

.enso-carousel__slide {
  height: 100%;
  width: 100vw;
}








