.button {
  @apply bg-gray-600 p-3 my-2 w-full align-middle text-white text-center inline-block;
}

@screen sm {
  .button {
    @apply px-20 w-auto;
  }

  .button + .button {
    @apply ml-4;
  }
}

.button:hover {
  @apply bg-gray-700;
}

.button:active {
  @apply bg-gray-800;
}

/* .has-triangle-before:before {
  content: "";
  @apply absolute border-b-font-dark border-l-transparent border-solid border-b-[50px] border-l-[10px] top-0 -left-2.5;
}

.has-triangle-after:after {
  content: "";
  @apply absolute border-t-font-dark border-r-transparent border-solid border-t-[50px] border-r-[10px] top-0 -right-2.5;
} */
