@tailwind base;
@tailwind components;
@import 'components/buttons.css';
@import 'components/carousel.css';
@import 'components/hamburger.min.css';
@import 'utilities/fonts.css';
@import 'utilities/media.css';
@tailwind utilities;

@layer base {
  h1,h2,h3,h4 {
    @apply font-title;
  }
  h2 {
    @apply font-title;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: -0.5px;
    color: #141313;
  }

  .featured p:first-of-type {
    @apply font-title;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.75px;
    color: #141313;
  }

  .button {
    @apply -skew-x-12;
    @apply transform-gpu;
    @apply uppercase;
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply max-w-fit;
    @apply font-title;
    @apply text-[14px];
    @apply text-center;
    @apply text-font-light;
    @apply bg-orange-500;
    @apply hover:bg-orange-600;
    @apply py-3.75;
    @apply px-7.5;
    @apply tracking-extrawide;
  }

  .button span {
    @apply block;
    @apply skew-x-12 ;
    @apply transform-gpu;
  }

  .vueperslide__title {
    @apply font-sans;
    font-weight: 300;
    font-size: 20px;
  }
  .label {
    @apply font-title;
    @apply uppercase;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 3.5px;
    color: #141313;
  }

  .page-break {
    page-break-after: always;
  }

  table {
    font-size: 12px;
    max-width: 2480px;
  }

  tr:first-child {
    padding: 1rem;
  }
  tr:last-child {
    padding: 1rem;
  }

  .report-table {
    background-color: #eee6e6;
  }
  .report-table th {
    background-color: #e3dddd;
    font-size: 13px;
    font-weight: 500;
    @apply font-title py-5;
    line-height: 1;
    max-width: 170px;
  }
  .report-table td {
    text-align: center;
  }
  .report-total {
    /* @apply text-white; */
  }
  .report-total th {
    @apply bg-orange-500 font-bold text-white;
  }
  .report-total td {
    @apply bg-orange-500/50;
  }
  .section-table {
    text-align: left;
    background-color: #eee6e6;
  }
  .section-table td, .section-table th {
  }
  .section-table th {
    @apply uppercase font-normal;
    background-color: #e3dddd;
  }
  .main-header th {
    background-color: black;
    color: white;
  }
}

.scroll-up {
  transition: all ease-in-out .2s;
  transform: translateY(0);
  position: fixed;
}

.scroll-down {
  transition: all ease-in-out .2s;
  transform: translateY(-200%);
}
.select-arrows {
  position: relative;
  @apply w-full;
}

.select-arrows select {
  @apply w-full tracking-wide p-4 bg-brown-100;
  cursor: pointer;
  padding-right: 2em;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-height: 3.125rem;
  background: url("../svg/droparrow.svg") no-repeat right rgb(238 230 230);
  background-position-x: 98%;
}

.search-club {
  position: relative;
  @apply w-full;
}

.search-club input {
  @apply w-full tracking-wide p-4 bg-brown-100;
  cursor: pointer;
  padding-right: 2em;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-height: 3.125rem;
  background: rgb(238 230 230);
}

.price-info ul li {
  /* list-style-image: url("{{ asset('./svg/check.svg') }}"); */
  padding-bottom: 1rem;
  list-style-image: url("../svg/check.svg");
  font-weight: 600;
}

.summary-table-heading {
  font-weight: 700 !important;
  text-transform: none !important;
}

.summary-table-font-bold {
  font-weight: 700 !important;
}

.md\:col-span-10 {
  grid-column: span 10 / span 10;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[38px\] {
  font-size: 38px;
}

.star-rating {
  width: 100px;
}

.z-5 {
  z-index: 5;
}

input.input-autocomplete {
  width: 50vw;
  height: 50px;
  padding: 10px 20px;
}

ul.autocomplete {
  width: 50vw;
  max-height: 200px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  background: #fff;
  text-align: left;
  overflow-y: auto;
}

li.autocomplete-item {
  padding: 8px 20px;
  cursor: pointer;
}

li.autocomplete-item:hover {
  background-color: #f0f0f0;
}

li.autocomplete-item a {
  display: block;
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  input.input-autocomplete {
    width: 80vw;
  }

  ul.autocomplete {
    width: 80vw;
  }
}